import Vue from "vue";
import moment from "moment-timezone";

Vue.filter("dateFormat", (value) => {
  if (!value) return "";
  return moment(value).tz("America/Caracas").format("DD/MM/YYYY");
});

Vue.filter("dateFormatWeek", (value, twoPaymentWeek) => {
  if (!value) return "";
  const date = moment(value);

  if (twoPaymentWeek) {
    // Special case for January 2, 2025, to January 5, 2025
    const targetStartDate = moment("2025-01-02");
    const targetEndDate = moment("2025-01-05");
    
    const day = date.day();
    let startDate, endDate;

    if (date.isBetween(targetStartDate, targetEndDate, null, "[]")) {
      startDate = moment("2025-01-02"); // Saturday
      endDate = moment("2025-01-05"); // Sunday
      return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
    }

    if (date.isBefore(targetStartDate)) {
        
      startDate = date.clone().day(0); 
      endDate = date.clone().day(3); 
      if(day > 3) {
        startDate = date.clone().day(4);
        endDate = date.clone().day(6); 
      }
      return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
    }

    if (day >= 1 && day <= 2) { // Day 1 to 2
      startDate = date.clone().day(1); // Monday
      endDate = date.clone().day(2); // Tuesday
    } else if (day >= 3 && day <= 4) { // Day 3 to 4
      startDate = date.clone().day(3); // Wednesday
      endDate = date.clone().day(4); // Thursday
    } else { // Day 5 to 7
      startDate = date.clone().day(5); // Friday
      endDate = date.clone().day(7); // Sunday
    }
    return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
  } else {
    // Regular weekly format
    return `${date.startOf("week").format("DD/MM/YYYY")} - ${date.endOf("week").format("DD/MM/YYYY")}`;
  }
});